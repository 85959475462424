import { DEL_ITEM_PERMISSION, MAINTAIN_PERMISSION, PPV_ACTUALS_ASSIGN_CATEGORIES } from "@kraftheinz/common";
import { M as MyUtil } from "./MyUtil.js";
import { n as normalizeComponent } from "./vueComponentNormalizer.js";
import "lodash";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "assign-categories__list" }, [_c("div", { staticClass: "px-2" }, [_c("a-row", { staticClass: "header-container mb-3 px-4 pt-2 pb-3", attrs: { "gutter": 16, "type": "flex" } }, [_c("a-col", { staticClass: "d-flex align-items-center flex-wrap", attrs: { "span": 20 } }, [_c("span", { staticClass: "title" }, [_vm._v("Assign PPV Categories")])])], 1)], 1), _c("div", { staticClass: "card p-3 mx-5" }, [_c("div", { staticClass: "header-title" }, [_vm._v("Filter By")]), _c("a-row", { attrs: { "gutter": 24, "type": "flex", "jusify": "center" } }, [_c("a-col", { staticClass: "pb-3", attrs: { "span": 6 } }, [_c("select-filter", { attrs: { "label": "Country", "source": "countryId", "placeholder": "Country", "data-source": _vm.countries, "source-label": _vm.generateCountryLabel, "default-active-first-option": "", "value": _vm.selectedCountry }, on: { "change": function($event) {
    return _vm.onSelectFilterChange($event, "CountryID");
  } } })], 1), _c("a-col", { staticClass: "pb-3", attrs: { "span": 6 } }, [_c("select-input", { attrs: { "label": "Version", "source": "versionId", "source-label": "versionName", "reference": "ppv.common.ppv-version", "placeholder": "Select Version", "queries": _vm.allQuery, "default-active-first-option": "", "value": _vm.VersionID }, on: { "change": function($event) {
    return _vm.onFilterChange($event, "VersionID");
  } } })], 1), _c("a-col", { staticClass: "pb-3", attrs: { "span": 6 } }, [_c("text-input", { attrs: { "label": "Fin Year", "placeholder": "Type Fin Year", "value": _vm.FinYear }, on: { "change": function($event) {
    return _vm.onInputFilterChange($event, "FinYear");
  } } })], 1), _c("a-col", { staticClass: "pb-3", attrs: { "span": 6 } }, [_c("select-input", { attrs: { "label": "PPV Category", "source": "categoryId", "source-label": "category", "reference": "ppv.common.ppv-category", "placeholder": "Select Category", "queries": _vm.allQuery, "default-active-first-option": "", "value": _vm.CategoryID }, on: { "change": function($event) {
    return _vm.onFilterChange($event, "CategoryID");
  } } })], 1), _c("a-col", { staticClass: "pb-3", attrs: { "span": 6 } }, [_c("text-input", { attrs: { "label": "Subclass", "placeholder": "Type Subclass", "value": _vm.Subclass }, on: { "change": function($event) {
    return _vm.onInputFilterChange($event, "SubClass");
  } } })], 1), _c("a-col", { staticClass: "pb-3", attrs: { "span": 6 } }, [_c("text-input", { attrs: { "label": "Item", "placeholder": "Type Item", "value": _vm.ItemCode }, on: { "change": function($event) {
    return _vm.onInputFilterChange($event, "ItemCode");
  } } })], 1), _c("a-col", { staticClass: "pb-3", attrs: { "span": 6 } }, [_c("text-input", { attrs: { "label": "Project", "placeholder": "Type Project", "value": _vm.Project }, on: { "change": function($event) {
    return _vm.onInputFilterChange($event, "Project");
  } } })], 1)], 1), _c("a-row", { attrs: { "type": "flex", "justify": "end" } }, [_c("a-col", { staticClass: "d-flex align-items-center" }, [_c("a-button", { staticClass: "mr-2", attrs: { "type": "primary", "form-item": "" }, on: { "click": _vm.onRefreshFilterClick } }, [_vm._v("Refresh")]), _c("a-button", { staticClass: "mr-2", attrs: { "icon": "close-circle", "form-item": "" }, on: { "click": _vm.onClearFilterClick } }, [_vm._v("Reset Filter")]), _c("a-button", { attrs: { "type": "primary", "form-item": "" }, on: { "click": _vm.showModal } }, [_vm._v("Show Help")]), _c("a-modal", { attrs: { "title": "HELP ON USING THIS FORM", "width": 550 }, on: { "ok": _vm.handleOk }, model: { value: _vm.visible, callback: function($$v) {
    _vm.visible = $$v;
  }, expression: "visible" } }, [_c("p", { staticClass: "neutral-10--text" }, [_vm._v(" - Start and End Dates must be in the same year ")]), _c("p", { staticClass: "neutral-10--text" }, [_vm._v(" - Project is required only for Category Productivity ")]), _c("p", { staticClass: "neutral-10--text" }, [_vm._v(" - Unit Rate not required for Allocation Type ALL ")]), _c("p", { staticClass: "neutral-10--text" }, [_vm._v(" - Enter either an item or a subclass depending on the Item/Subclass drop down ")]), _c("template", { slot: "footer" }, [_c("a-button", { key: "submit", attrs: { "type": "primary" }, on: { "click": _vm.handleOk } }, [_vm._v("Ok")])], 1)], 2)], 1)], 1)], 1), _c("list-composer", { attrs: { "search-by": "", "ph-search-by": "Search by ", "has-active": false, "has-filter-inactive": false }, scopedSlots: _vm._u([_vm.can(_vm.permissions.maintain) ? { key: "customButton", fn: function() {
    return [_c("a-button", { attrs: { "type": "primary" }, on: { "click": _vm.onAddNewClick } }, [_vm._v("Add New")])];
  }, proxy: true } : null, { key: "action", fn: function(ref) {
    var edit = ref.edit;
    var record = ref.record;
    return [_vm.can(_vm.permissions.maintain) ? _c("a-tooltip", { scopedSlots: _vm._u([{ key: "title", fn: function() {
      return [_vm._v("Edit")];
    }, proxy: true }], null, true) }, [_c("a-button", { staticClass: "mr-2", attrs: { "icon": "edit", "size": "small", "type": "link" }, on: { "click": function($event) {
      return edit(record);
    } } })], 1) : _vm._e(), _vm.can(_vm.permissions.del) ? _c("a-tooltip", { scopedSlots: _vm._u([{ key: "title", fn: function() {
      return [_vm._v("Delete")];
    }, proxy: true }], null, true) }, [_c("a-button", { attrs: { "icon": "delete", "size": "small", "type": "link" }, on: { "click": function($event) {
      return _vm.onDel(record);
    } } })], 1) : _vm._e()];
  } }], null, true) }, [_c("text-field", { key: "versionDesc", attrs: { "title": "VersionID", "data-index": "versionDesc", "sorter": true } }), _c("text-field", { key: "itemSubClassFlagDesc", attrs: { "title": "ItemSubClassFlag", "data-index": "itemSubClassFlagDesc", "sorter": true } }), _c("text-field", { key: "itemDesc", attrs: { "title": "Item Code", "data-index": "itemDesc", "sorter": true } }), _c("text-field", { key: "subClassDesc", attrs: { "title": "Subclass", "data-index": "subClassDesc", "sorter": true } }), _c("text-field", { key: "category", attrs: { "title": "CategoryID", "data-index": "category", "sorter": true } }), _c("text-field", { key: "startDate", attrs: { "title": "StartDate", "data-index": "startDate", "parse": _vm.parseDate, "sorter": true } }), _c("text-field", { key: "endDate", attrs: { "title": "EndDate", "data-index": "endDate", "parse": _vm.parseDate, "sorter": true } }), _c("text-field", { key: "project", attrs: { "title": "Project", "data-index": "project", "sorter": true } }), _c("text-field", { key: "allocationTypeDesc", attrs: { "title": "AllocationType", "data-index": "allocationTypeDesc", "sorter": true } }), _c("text-field", { key: "unitRate", attrs: { "title": "UnitRate", "data-index": "unitRate", "sorter": true } })], 1)], 1);
};
var staticRenderFns$1 = [];
var AssignCategories_vue_vue_type_style_index_0_scoped_true_lang = "";
const __vue2_script$1 = {
  name: "AssignCategories",
  inject: ["crud", "can"],
  mixins: [MyUtil],
  data() {
    return {
      visible: false,
      permissions: {
        del: DEL_ITEM_PERMISSION,
        maintain: MAINTAIN_PERMISSION
      }
    };
  },
  computed: {
    Subclass() {
      const value = this.crud.getQueryString("SubClass");
      return value ? value.value : "";
    },
    FinYear() {
      const value = this.crud.getQueryString("FinYear");
      return value ? value.value : 0;
    },
    ItemCode() {
      const value = this.crud.getQueryString("ItemCode");
      return value ? value.value : "";
    },
    Project() {
      const value = this.crud.getQueryString("Project");
      return value ? value.value : "";
    },
    VersionID() {
      const value = this.crud.getQueryString("VersionID");
      return value ? value.value : 0;
    },
    CategoryID() {
      const value = this.crud.getQueryString("CategoryID");
      return value ? value.value : 0;
    },
    allQuery() {
      return [["All", true]];
    }
  },
  created() {
    this.getCountries();
    this.crud.setQueryString("CountryID", this.countryIdAU);
  },
  methods: {
    parseDate(value) {
      if (!value)
        return "";
      return this.$moment(`${value}`).format("YYYY/MM/DD");
    },
    onRefreshFilterClick() {
      this.crud.fetchList();
    },
    showModal() {
      this.visible = true;
    },
    handleOk() {
      this.visible = false;
    },
    onAddNewClick() {
      this.$router.push("/ppv/assign-categories/create");
    },
    onDel(record) {
      this.$confirm({
        class: "list__modal-delete",
        icon: "delete",
        title: `Do you want to delete this Assign Category`,
        content: `If you click Yes, you won\u2019t be able to undo this Delete operation. Are you sure you want delete this Assign Category?`,
        cancelText: "No",
        okText: "Yes",
        okType: "danger",
        onOk: this.onConfirmDelete(record)
      });
    },
    onConfirmDelete(record) {
      return () => {
        this.axios.put(`${this.apiUrl}/ppv/category-assigns/${record.assignId}`, {
          CountryId: record.countryId,
          VersionId: record.versionId,
          ItemSubClassFlag: record.itemSubClassFlag,
          SubClass: record.subClass,
          CategoryId: record.categoryId,
          StartDate: record.startDate,
          EndDate: record.endDate,
          AllocationType: record.allocationType,
          UpdateType: "delete",
          UnitRate: record.unitRate,
          ItemCode: record.itemCode,
          Project: record.project
        }).then(() => {
          this.crud.fetchList();
          this.$notification["success"]({
            message: "Item Deleted!"
          });
        }).catch(() => {
          this.$notification["error"]({
            message: "Something went wrong, please try again!"
          });
        });
      };
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, "5ce0743a", null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var AssignCategories = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", [_c("bread-crumb", { attrs: { "items": _vm.itemsMenu } }), _c("resource", { attrs: { "api-url": _vm.apiUrl, "name": "ppv.common.ppv-version" } }), _c("resource", { attrs: { "api-url": _vm.apiUrl, "name": "ppv.common.ppv-category" } }), _c("resource", { attrs: { "api-url": _vm.apiUrl, "name": "ppv.category-assigns", "resource-id-name": "assignId", "edit-route": "/ppv-actuals/assign-categories/:id", "page": _vm.page } }, [_c("assign-categories")], 1), _c("router-view")], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: {
    AssignCategories
  },
  data() {
    return {
      apiUrl,
      page: PPV_ACTUALS_ASSIGN_CATEGORIES,
      itemsMenu: [
        {
          key: "ppv-actuals",
          title: "PPV Actuals",
          path: ""
        },
        {
          key: "assign-categories",
          title: "Assign Categories",
          path: "/ppv-actuals/assign-categories"
        }
      ]
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
